<div class="popup-container">
  <div class="popup-header">
    <ion-icon name="newspaper" class="popup-icon"></ion-icon>
    <h2>New Release Details</h2>
  </div>
  <div class="popup-content">
    <h3>Corrective Action:</h3>
    <p>
      The <b>Corrective Action</b> tab is now available in the menu. You can now easily view and
      add comments to your Corrective Actions. Explore this feature starting Monday, November 18!
    </p>
  </div>
  <div class="popup-footer">
    <button (click)="closeDialog()">OK</button>
  </div>
</div>