import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from '../../services/authorization.service';
import { Role } from 'src/app/features/models/Role';
import { Subject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { MatDialog } from '@angular/material/dialog';
import { AlertModelComponent } from 'src/app/features/components/corrective-action/alert-model/alert-model.component';
import { NewReleaseDetailsComponent } from 'src/app/features/components/new-release-details/new-release-details.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {

  date = new Date()
  appversion = environment.appVersion
  empID = this.authService.empID
  empName = this.authService.empName
  EmpSubGroup = this.authService.EmpSubGroup
  appRoleId = this.authService.appRoleID
  plant = this.authService.plant
  showMyteam: boolean = false
  darkMode: boolean = false
  showNewRelease: boolean = false;
  userActivity: any;
  userInactive: Subject<any> = new Subject();

  //Set target time for when new release tab here
  targetDate = new Date('2024-11-18T09:00:00Z');
  currentDate: any;

  // Set desired inactivity time here
  // Current: 600000 ms or 10 minutes
  inactivityTime: number = 600000;

  // 15 seconds before the popup automatically logs you off
  automaticCloseTimer: number = 15000;

  userProfileImageURL = environment.wfmAPIBaseURL + "employee/image?EmpID=" + this.empID

  public MenuItems = [

    {
      link: "home",
      name: "Home",
      icon: "home",
      allowedRole: [Role.MIM_Salaried, Role.MIM_Hourly, Role.IOS_NONEXMPT, Role.IOS_EXMPT, Role.IOS_NONEXMPT_AGENCY, Role.MIM_NF, Role.NON_MIMNF]
    },
    {
      link: "my-team",
      name: "My Team",
      icon: "people",
      allowedRole: []
    },
    {
      link: "hours-worked",
      name: "My Time Card",
      icon: "timer",
      allowedRole: [Role.MIM_Hourly, Role.IOS_NONEXMPT, Role.IOS_NONEXMPT_AGENCY, Role.MIM_NF, Role.NON_MIMNF]
    },
    {
      link: "occurrence",
      name: "Occurrence Details",
      icon: "reload-circle",
      allowedRole: [Role.MIM_Hourly, Role.IOS_NONEXMPT, Role.IOS_NONEXMPT_AGENCY]
    },
    {
      link: "certification-details",
      name: "Certification Details",
      icon: "ribbon",
      allowedRole: [Role.MIM_Salaried, Role.MIM_Hourly, Role.IOS_EXMPT, Role.IOS_NONEXMPT, Role.IOS_NONEXMPT_AGENCY, Role.MIM_NF, Role.NON_MIMNF]
    },
    {
      link: "corrective-action",
      name: "Corrective Action",
      icon: "alert-circle",
      allowedRole: [Role.MIM_Salaried, Role.MIM_Hourly, Role.IOS_EXMPT, Role.IOS_NONEXMPT, Role.IOS_NONEXMPT_AGENCY]
    },
    {
      link: "mim-job",
      name: "MIM Internal Jobs",
      icon: "briefcase",
      allowedRole: [Role.MIM_Salaried, Role.MIM_Hourly, Role.IOS_NONEXMPT, Role.IOS_NONEXMPT_AGENCY]
    },
    {
      link: "reference materials",
      name: "Reference Materials",
      icon: "information-circle",
      allowedRole: [Role.MIM_Salaried]
    },
    {
      link: "internal-mimlinks",
      name: "Internal MIM-Links",
      icon: "server",
      allowedRole: [Role.MIM_Salaried, Role.MIM_NF]
    },
    // {
    //   link: "attendance-occurance",
    //   name: "Attendance Corrective Action",
    //   icon: "calculator",
    //   allowedRole:[Role.MIM_Salaried]
    // }
  ];

  constructor(
    public authService: AuthorizationService,
    private route: Router,
    private modalCtrl: ModalController,
    private dialog: MatDialog
  ) {
    if (this.authService.jobRole) {
      this.showMyteam = this.authService.EmpSubGroupID !== 103 && (this.appRoleId == 103 || this.authService.jobRole.toUpperCase() == 'SUPERVISOR' || this.authService.jobRole.toUpperCase() == 'MANAGER' || this.authService.jobRole.toUpperCase() == 'LEAD');
    }
  }

  ngOnInit() {
    this.updateTheme();

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', this.updateTheme);

    this.setTimeout();

    this.userInactive.subscribe(() => {
      this.showInactivityDialog();
    });

    this.currentDate = new Date(Date.now());
    this.showNewRelease = (this.plant!="NF") && (this.currentDate>=this.targetDate);
  }


  setTimeout() {
    clearTimeout(this.userActivity);
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), this.inactivityTime);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  @HostListener('window:keydown') clickUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  @HostListener('window:scroll') keydownUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  async showInactivityDialog() {
    const modal = await this.modalCtrl.create({
      component: AlertModelComponent,
      cssClass: 'dialog',
      componentProps: {
        note: 'You will be automatically logged out if you do not select an option.',
        DialogBoxFor: 'Inactivity',
        lbl: 'You have been inactive for a while. Are you still there?',
        title: 'Inactivity Warning',
      }
    });

    await modal.present();

    modal.onDidDismiss().then((m) => {
      if (m.role === 'confirm') {
        this.setTimeout();
      } else {
        clearTimeout(this.userActivity);
        this.authService.logOut();
      }
    });

    setTimeout(() => {
      modal.dismiss('cancel');
    }, this.automaticCloseTimer);
  }

  updateTheme() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    document.body.setAttribute('color-theme', prefersDark ? 'dark' : 'light');
    document.documentElement.classList.toggle('ion-palette-dark', prefersDark);
    this.darkMode = prefersDark;
  }

  toggleDarkMode(event: any) {
    document.documentElement.classList.toggle('ion-palette-dark', event.detail.checked);
    document.body.setAttribute('color-theme', event.detail.checked ? 'dark' : 'light');
  }

  showNewReleaseDetails(){
    const dialogRef = this.dialog.open(NewReleaseDetailsComponent, {
      width:'800px',
      disableClose: true,
      autoFocus: false,
      data: 'row',
    });
    dialogRef.afterClosed()
  }

  logOut() {
    this.authService.logOut()
  }

  changePassword() {
    this.route.navigateByUrl('change-password');
  }

  ngOnDestroy() {
    clearTimeout(this.userActivity);
  }
}
