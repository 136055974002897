import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { RegistrationComponent } from './core/components/registration/registration.component';
import { ResetPasswordComponent } from './core/components/reset-password/reset-password.component';

import { MainComponent } from './core/components/main/main.component';
import { LoginComponent } from './core/components/login/login.component';
// import { EmployeeDetailsComponent } from './features/components/employee-details/employee-details.component';
import { NotificationsComponent } from './features/components/notifications/notifications.component';
import { AdminCorrectiveActionComponent } from './features/components/corrective-action/admin-corrective-action/admin-corrective-action.component';
import { ChangePasswordComponent } from './core/components/change-password/change-password.component';


export const  routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'employee-portal',
    component: MainComponent,

    children:[
      {
        path: '',
        loadChildren:
          () => import('./features/features.module').then(m => m.FeaturesModule)
      },

    ]

  },

  {
    path: 'registration',
    component: RegistrationComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path:'reset-password',
    component:ResetPasswordComponent
  },
  {
    path:'change-password',
    component:ChangePasswordComponent
  },


  {
    path:'notifications',
    component:NotificationsComponent
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],

})



export class AppRoutingModule {}
