import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
}
    from '@angular/common/http';

import { from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService {

    constructor(private cookieService: CookieService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //DOES NOT NEED AUTH - We are checking for our "skip-auth" header
        if (request.headers.has('skip-auth')) {
            //console.log("skip-auth")
            request = request.clone({
                //Remove the "skip-auth" header so it does not get sent to the server
                headers: request.headers.delete('skip-auth'),
            });
            return next.handle(request).pipe(
                map((event: HttpEvent<any>) => {
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
        } else {
            //NEEDS AUTH
            //Add the Authorization to our header before sending off the request
            var token: string = "";
            //  console.log('intercept:  '+ this.cookieService.get('access_token'));
            token = this.cookieService.get('access_token').toString()
            if (token == "" || token == undefined)
                token = this.cookieService.getAll()['access_token'];
            //   console.log(this.cookieService.get('access_token'))
            const newRequest = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                },
            });
            return next.handle(newRequest);
        }
    }
}