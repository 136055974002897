<ion-content class="resetContent">
  <div class="flex-center">
    <ion-card class="reset-bx">
      <ion-card-header>
        <div class="flex-center"><img alt="homepage" src="assets/background/mim_log.png" width="55px">
          <ion-card-title>Update Password</ion-card-title>
        </div>
      </ion-card-header>

      <ion-card-content>
        <form [formGroup]="resetForm">
          <ion-item class="formInputs">
            <ion-input label="EmployeeID" labelPlacement="floating" type="text" formControlName="employeeId"
              class="form-control" placeholder=" "></ion-input>
          </ion-item>

          <ion-item>
            <ion-input label="Enter New Password:" labelPlacement="floating"
              type="{{ showPassword ? 'text' : 'password' }}" formControlName="newPassword" class="form-control"
              placeholder=" "></ion-input>

            <ion-icon name="{{ showPassword ? 'eye-off' : 'eye' }}" slot="end"
              (click)="togglePasswordVisibility()"></ion-icon>
          </ion-item>

          <ion-label color="danger" *ngIf="this.resetForm.controls['newPassword'].value == this.hiredYear ">* Please Do
            Not enter hired year! </ion-label>

          <ion-item class="formInputs">
            <ion-input label="Re-Enter New Password:" (keypress)="eventHandler($event)" labelPlacement="floating"
              type="{{ showPassword ? 'text' : 'password' }}" formControlName="resetNewPassword" class="form-control"
              placeholder="Re-Enter New Passwor "></ion-input>
            <ion-icon name="{{ showPassword ? 'eye-off' : 'eye' }}" slot="end"
              (click)="togglePasswordVisibility()"></ion-icon>
          </ion-item>

          <ion-item class="formInputs">
            <ion-input label="Password Hint:" (keypress)="eventHandler($event)" labelPlacement="floating" type="text"
              formControlName="passwordHint" class="form-control" placeholder=" "></ion-input>
          </ion-item>

          <ion-label color="danger"
            *ngIf="this.resetForm.controls['newPassword'].touched && this.resetForm.controls['passwordHint'].value === resetForm.controls['newPassword'].value">*
            Password hint cannot be the same as new Password! </ion-label>

          <ion-button class="resetbtn" size="full" (click)="resetPassword()">Update Password</ion-button>
        </form>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>