<ion-menu contentId="content" type="overlay">
  <ion-header>
    <ion-toolbar>
      <ion-title class="title-text">Employee Portal {{appversion}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" [fullscreen]="true">
    <ion-item class="menuTopbtn">
      <ion-button expand="full" fill="clear" (click)="changePassword()">
        <ion-icon name="log-out-outline"></ion-icon> Update Password
      </ion-button>
    </ion-item>
    <ion-item class="menuTopbtn" *ngIf="showNewRelease">
      <ion-button expand="full" fill="clear" (click)="showNewReleaseDetails()">
        <ion-icon name="information-circle-outline"></ion-icon> New Release Details
      </ion-button>
    </ion-item>
    <ion-item class="menuTopbtn">
      <ion-toggle labelPlacement="end" (ionChange)="toggleDarkMode($event)" [checked]="darkMode" justify="space-between"
        color="dark"> Dark Mode</ion-toggle>
    </ion-item>
    <span layout="row">
      <hr flex />
    </span>
    <ion-list id="inbox-list">
      <ion-menu-toggle *ngFor="let p of MenuItems;">
        <ion-item [routerLink]="[p.link]" lines="none" routerLinkActive="selected"
          *ngIf="(p.allowedRole.includes(this.EmpSubGroup) && p.link!='corrective-action')||(p.link=='my-team'&& this.showMyteam)||(p.link=='corrective-action' && p.allowedRole.includes(this.EmpSubGroup) && showNewRelease)">
          <ion-icon aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
          <ion-label class="menuItems">{{ p.name }}</ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-list>
  </ion-content>
</ion-menu>

<div class="ion-page" id="content">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start" class="menubtn">
        <span class="menuText">Menu</span>
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <div class="navbar-header">
        <a class="navbar-brand" href="employee-portal/home">
          <img src="assets/icon/MIM_Logo_W.png" alt="homepage" class="light-logo" width="120px" />
        </a>
      </div>
      <ion-buttons style="margin-right:1%" slot="end">
        <ion-button class="logout-text" (click)="logOut()">
          <ion-icon class="logout-icon" name="log-out-outline"></ion-icon>
          <span class="underline"> Log Out</span>
        </ion-button>
      </ion-buttons>
      <div class="welcome-header">
        <div>
          <img alt="user" src="{{ userProfileImageURL }}" width="80" class="image-circle" />
        </div>
        <div class="welcomeText">
          <h1><b>Welcome</b> {{empName}} ({{empID}})</h1>
          <div class="date">
            <p class="date-text">{{date | date:'fullDate'}}</p>
            <ion-button fill="clear" class="homeIcon" size="small" routerLink="/employee-portal/home">
              <ion-icon size="small" name="home" class="home"></ion-icon>
              <span class="homeText underline">Home</span>
            </ion-button>
          </div>
        </div>
        <hr />
      </div>
    </ion-toolbar>
  </ion-header>

  <ion-content id="content" class="ion-padding">
    <ion-router-outlet id="content">
    </ion-router-outlet>
  </ion-content>
</div>