import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  ToastController,
  LoadingController,
  AlertController,
} from '@ionic/angular';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { User } from 'src/app/features/models/User';
import { CookieService } from 'ngx-cookie-service';

const CURRENT_USER: string = 'currentUser';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  baseUrl = environment.baseUrl;

  user: User = <User>{};
  empID: any = '';
  empName: any = '';
  empEmail: any = '';
  empAddress: any = '';
  empContactNumber: any = '';
  appRoleID: any = '';
  jobRole: any = '';
  jobTitle: any = '';
  agency: any = '';
  dept: any = '';
  hiredOn: any = '';
  EmpSubGroup: any
  EmpSubGroupID: any;
  shift: any = '';
  plant: any = '';
  email: any = '';
  yearHired: any = '';
  password: any = '';
  mimEmail: any;
  IsITGroupMemb!: boolean;
  HasMiMEmail!: boolean

  token: any = '';

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private http: HttpClient,
    public router: Router,
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController,
    private alertController: AlertController,
    private cookieService: CookieService,
  ) {
    //  this.empName = sessionStorage.getItem('EMP_Name');
    this.currentUserSubject = new BehaviorSubject<any>(
      this.getUserFromSessionStorage()
    );
    this.setUser();
  }

  setUser = () => {
    this.user = this.currentUserSubject.value;
    this.empID = this.user.EmpID;
    this.empName = this.user.EmpName;
    this.email = this.user.EmpEmail;
    this.yearHired = this.user.HiredOn;
    this.plant = this.user.Plant;
    this.password = this.user.UserPassword;
    this.empContactNumber = this.user.ContactNumber;
    this.empEmail = this.user.EmpEmail;
    this.empAddress = this.user.EmpAddress;
    this.appRoleID = this.user.AppRoleID;
    this.jobRole = this.user.JobRole;
    this.jobTitle = this.user.JobTitle;
    this.hiredOn = this.user.HiredOn;
    this.agency = this.user.Agency;
    this.shift = this.user.Shift;
    this.dept = this.user.Dept;
    this.mimEmail = this.user.MimEmail;
    this.EmpSubGroupID = this.user.EmpSubGroupID;
    this.EmpSubGroup = this.user.EmpSubGroup;
    this.IsITGroupMemb = this.user.IsITGroupMemb;
    this.HasMiMEmail = this.user.HasMiMEmail

  };

  registerUser = (empID: string, email: string, hireYear: string): Observable<any> => {
    let userInput: any = { EmpID: empID, EmpEmail: email, YearHired: hireYear };

    return this.userRegistration(userInput).pipe(tap(async (res: any) => {
      // console.log(res);
      if (res[0]['api_message'] !== undefined) {
        if (res[0]['api_message'].includes("already registered")) {
          const alert = await this.alertController.create({
            header: 'Registration Failed',
            subHeader: res[0]['api_message'],
            buttons: ['Okay'],
          });
          await alert.present();
          this.router.navigateByUrl('login')
        }
        else if (res[0]['api_message'].includes("Invalid user")) {
          const alert = await this.alertController.create({
            header: 'Registration Failed',
            subHeader: res[0]['api_message'],
            buttons: ['Okay'],
          });
          await alert.present();
          // this.router.navigateByUrl('login')
        }
      }
      else {
        sessionStorage.setItem('EMP_ID', res[0].EmpID);
        sessionStorage.setItem('EMP_HireYear', res[0].YearHired);
        this.empID = sessionStorage.getItem('EMP_ID');
        this.yearHired = sessionStorage.getItem('EMP_HireYear');

        const alert = await this.alertController.create({
          header: 'Registration Success',
          subHeader: 'You have successfully registered!',
          buttons: ['Okay'],
        });
        await alert.present();
        this.router.navigateByUrl('change-password');
      }

    })
    );
  };

  userRegistration = (userInput: any): any => {
    const headers = new HttpHeaders({
      'skip-auth': 'true', //skip auth
    })

    return this.http
      .post<any[]>(this.baseUrl + 'user_auth/register', userInput, { headers })
      .pipe(
        map((res: any[]) => {
          return res;
        })
      );
  };

  userInfo = (empID: number): any => {
    return this.http
      .get<any[]>(this.baseUrl + 'employee?emp_id=' + empID)
      .pipe(
        map((res: any[]) => {
          return res
        })
      )
  }

  userHint = (empID: number): any => {
    return this.http
      .get<any[]>(this.baseUrl + 'hint?emp_id=' + empID)
      .pipe(
        map((res: any[]) => {
          return res;
        })
      )
  }

  isAuthorized(allowedRoles: string[]): boolean {
    if (this.empID == undefined || this.EmpSubGroup == undefined) {
      this.router.navigateByUrl('login')
    }
    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }
    return allowedRoles.includes(this.EmpSubGroup);
  }

  changePassWord = (newPassWordBody: any): any => {
    return this.http
      .put<any[]>(this.baseUrl + 'user_auth', newPassWordBody)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  };

  resetPassWord = (resetPassBody: any): any => {
    const headers = new HttpHeaders({
      'skip-auth': 'true', //skip auth
    })

    return this.http
      .post<any[]>(this.baseUrl + 'user_auth/reset', resetPassBody, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  };

  getauthToken = (user: any): Observable<any> => {

    const headers = new HttpHeaders({
      'skip-auth': 'true', //skip auth
    })

    return this.http.post<any>(this.baseUrl + 'token', user, { headers }).pipe(
      map((res: any) => res)
    );

  };

  getUser = (myToken: any): Observable<any> => {
    return this.http.get(this.baseUrl + 'user').pipe(
      map((res: any) => res)
    );
  };

  async clearALL() {
    await sessionStorage.clear();
    await localStorage.clear();
    // await this.cookieService.set('access_token', "");
    await this.cookieService.deleteAll('/');
    await this.cookieService.deleteAll('/empApp');
  }


  logOut() {
    document.body.classList.toggle('dark', false);
    /*
        sessionStorage.clear();
        localStorage.clear();
        this.cookieService.set('access_token', "");
        this.cookieService.deleteAll('/');
        this.cookieService.deleteAll('/empApp');
    */

    this.clearALL();
    this.invalidateUser();
    //this.router.navigateByUrl('login')
    window.location.href = "/empApp/login"
    console.log('token' + this.cookieService.get('access_token'))
  }

  async setUserAfterLogin(user: User) {
    this.saveUserToSessionStorage(user);
    this.currentUserSubject.next(user);
    this.setUser();
  }

  invalidateUser = () => {
    this.user = <User>{};
    this.removeUserFromSessionStorage();
    this.currentUserSubject.next(<User>{});
  };

  private saveUserToSessionStorage(user: User) {
    sessionStorage.setItem(CURRENT_USER, JSON.stringify(user));
  }

  private removeUserFromSessionStorage() {
    sessionStorage.removeItem(CURRENT_USER);
  }

  private getUserFromSessionStorage(): User {
    return JSON.parse(sessionStorage.getItem(CURRENT_USER) || '{}');
  }
}


// login = (formData: any): Observable<any> => {
//   let user: any = formData;
//  let userinfo:any;
//  return this.getauthToken(user);


// };

//   getauthToken = (user: any): Observable<any> => {
//     return this.http.post<any>(this.baseUrl + 'token', user).pipe(
//   //  return this.http.post<any>(this..to.tokenUrl + 'token', user).pipe(
//       switchMap((res: any) => {

//         this.token = res.access_token;
//         sessionStorage.setItem('access_token', res.access_token);
//         this.cookieService.set('access_token', res.access_token);
//         let cookieValue = this.cookieService.get('access_token');
//         return this.getUser(this.token);


//       })
//     );
//   };

//  getUser = (myToken: any): Observable<any> => {
//     const headers = new HttpHeaders({
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${myToken}`,
//     });

//    // console.log('headers', headers);

//     return  this.http.get(this.baseUrl + 'user', { headers }).pipe(
//       map(async(res: any) => {
//      //   console.log('inside get user');
//      //   console.log(res);
//         let user:any
//         user = res[0]

//         this.empName =res[0].EmpName
//         this.empID =  res[0].EmpID;



//         sessionStorage.setItem('EMP_Name', res[0].EmpName);
//         sessionStorage.setItem('EMP_ContactNumber', res[0].ContactNumber);
//         sessionStorage.setItem('EMP_ID', res[0].EmpID);
//         sessionStorage.setItem('EMP_Email', res[0].EmpEmail);
//         sessionStorage.setItem('EMP_AppRoleID', res[0].AppRoleID);
//         sessionStorage.setItem('EMP_JobRole', res[0].JobRole);
//         sessionStorage.setItem('EMP_JobTitle', res[0].JobTitle);
//         sessionStorage.setItem('EMP_HiredOn', res[0].HiredOn);
//         sessionStorage.setItem('EMP_Agency', res[0].Agency);
//         sessionStorage.setItem('EMP_Shift', res[0].Shift);
//         sessionStorage.setItem('EMP_MimEmail', res[0].MimEmail);
//         sessionStorage.setItem('EMP_Address', res[0].EmpAddress);
//         sessionStorage.setItem('EMP_MimEmail', res[0].EmpSubGroupID);
//         sessionStorage.setItem('EMP_Address', res[0].EmpSubGroup);



//         this.empEmail = sessionStorage.getItem('EMP_Email');
//         this.appRoleID = sessionStorage.getItem('EMP_AppRoleID');
//         this.jobRole = sessionStorage.getItem('EMP_JobRole');
//         this.jobTitle = sessionStorage.getItem('EMP_JobTitle');
//         this.hiredOn = sessionStorage.getItem('EMP_HiredOn');
//         this.agency = sessionStorage.getItem('EMP_Agency');
//         this.shift = sessionStorage.getItem('EMP_Shift');
//         this.empAddress = sessionStorage.getItem('EMP_Address');
//         this.EmpSubGroupID = sessionStorage.getItem('EmpSubGroupID');
//         this.EmpSubGroup = sessionStorage.getItem('EmpSubGroup');

//         this.setUserAfterLogin(user);

//         user;


//       })
//     );
//   };
