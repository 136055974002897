<ion-content class="resetContent">
  <div class="flex-center">
    <ion-card class="reset-bx">
      <ion-card-header>
        <div class="flex-center"><img alt="homepage" src="assets/background/mim_log.png" width="55px">
          <ion-card-title>Reset Password</ion-card-title>
        </div>
      </ion-card-header>

      <ion-card-content>
        <form [formGroup]="resetForm">
          <ion-item>
            <ion-input label="EmployeeID" labelPlacement="floating" type="text" (keypress)="eventHandler($event)"
              formControlName="employeeId" class="form-control" placeholder=" "></ion-input>
          </ion-item>

          <ion-button class="resetbtn" size="full" (click)="resetPassword()">Reset Password</ion-button>
          <div class="forgot">
            <a class="link" routerLink="/login"><ion-icon name="arrow-undo-outline"></ion-icon>Return To Login</a>
          </div>
        </form>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>