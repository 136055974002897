<ion-content class="login-register">
  <div class="flex-center">
    <ion-card class="login-box">
      <ion-card-header>
        <div class="flex-center"><img alt="homepage" src="assets/background/mim_log.png" width="55px">
          <ion-card-title>Employee Portal App {{version}}</ion-card-title>
        </div>
      </ion-card-header>

      <ion-card-content>
        <form [formGroup]="loginForm">
          <ion-item class="formInputs" style="margin-bottom: 3px;">
            <ion-input label="Enter your Employee ID:" labelPlacement="floating" type="text" maxlength="6"
              formControlName="username" placeholder="Enter your badge/Employee ID"></ion-input>
          </ion-item>
          <ion-label color="danger"
            *ngIf="this.loginForm.controls['username'].touched && this.loginForm.controls['username'].invalid">*
            EmployeeID is required</ion-label>

          <ion-item>
            <ion-input label="Enter your Password:" labelPlacement="floating" (keypress)="eventHandler($event)"
              type="{{ showPassword ? 'text' : 'passWord' }}" formControlName="password"></ion-input>

            <ion-icon name="{{ showPassword ? 'eye-off' : 'eye' }}" slot="end"
              (click)="togglePasswordVisibility()"></ion-icon>
          </ion-item>
          <ion-label color="danger"
            *ngIf="this.loginForm.controls['password'].touched && this.loginForm.controls['password'].invalid">*
            Password is required</ion-label>
          <div *ngIf="showLoginError">
            <p class="incorrect">{{loginErrorMessage}}</p>
          </div>
          <div *ngIf="showPasswordHint">
            <p class="hint">{{passwordHint}}</p>
          </div>

          <div class="forgot">
            <ion-button class="link" fill="clear" (click)="togglePasswordHint()">{{ hintText }}</ion-button>
            <ion-button class="link" fill="clear" (click)="resetPassword()">Forgot Your Password?</ion-button>
          </div>

          <ion-button class="loginBtn" size="full" (click)="login()">Login</ion-button>
          <ion-button class="registerBtn" (click)="navigateToRegistration()" size="full"
            style="margin-top: 18px;">Register</ion-button>
        </form>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>