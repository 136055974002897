import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CorrectiveActionModel } from '../models/correctiveAction';
import { EmployeeModel } from '../models/employee';

@Injectable({
  providedIn: 'root'
})
export class CorrectiveActionService {
  baseURL = environment.baseCorrActUrl;

  constructor(private http: HttpClient) { }

  getCorrectiveAction = (empID: number) : Observable<any> => {
    return this.http
        .get<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&emp_id='+empID)
        .pipe(
          map((res: any) => res)
        );
  }

  deleteCorrectiveAction = (corr_act_id: string) : Observable<any> => {
    return this.http
        .delete<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&corr_act_id='+corr_act_id+"&is_deleted=1&LastModifiedByID="+(Number)(sessionStorage.getItem("EMP_ID")))
        .pipe(
          map((res: any) => res)
        );
  }

  getCorrectiveActionByCorrActID = (corr_act_id: string) : Observable<any> => {
    return this.http
        .get<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&corr_act_id='+corr_act_id)
        .pipe(
          map((res: any) => res)
        );
  }

  getEmployeeCorrectiveAction = (empID: number) : Observable<any> => {
    return this.http
        .get<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&emp_id='+empID+'&app_role_id=104&is_emp_notified=1')
        .pipe(
          map((res: any) => res)
        );
  }
  // getEmployeeAllCorrectiveAction = (empID: number,agency:string) : Observable<any> => {
  //   if (agency!='MIMFB')
  //        return this.http
  //   .get<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&emp_id='+empID+ '&agency='+agency)
  //   .pipe(
  //     map((res: any) => res)
  //   );
  //   return this.http
  //       .get<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&emp_id='+empID)
  //       .pipe(
  //         map((res: any) => res)
  //       );
  // }


  getEmployeeAllCorrectiveAction = (empID: number,CorrActAppRoleID:number) : Observable<any> => {
    if (CorrActAppRoleID==106)
         return this.http
    .get<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&emp_id='+empID+ '&agency_id=526')
    .pipe(
      map((res: any) => res)
    );

    else
    return this.http
        .get<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&emp_id='+empID)
        .pipe(
          map((res: any) => res)
        );
  }

  getCorrectiveActionByStatus = (statusID: number) : Observable<any> => {
    return this.http
        .get<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&corr_act_status_id='+statusID)
        .pipe(
          map((res: any) => res)
        );
  }

  getCorrectiveActionAssigned = (badgeID: number,statusID: number) : Observable<any> => {
    return this.http
        .get<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&assigned_to_id='+badgeID+"&corr_act_status_id="+statusID)
        .pipe(
          map((res: any) => res)
        );
  }

  getCorrectiveActionG = (badgeID: number,statusID: number,empID:number,assignID:number,createrID:number,isActive:number,managerId:number,isEmpNotified:number,isOpen:number,violTypeId:number,isPending:number) : Observable<any> => {
    let querystring=""
    //console.log(managerId)
   if (badgeID!=0)
      querystring=querystring+ '&assigned_to_id='+badgeID
   if (statusID!=0)
      querystring=querystring+ '&corr_act_status_id='+statusID
   if(empID!=0)
    querystring=querystring+ '&emp_id='+empID
   if(assignID!=0)
    querystring=querystring+ '&assigned_to_id='+assignID
   if(createrID!=0)
    querystring=querystring+ '&created_by_id='+createrID
  
  if (sessionStorage.getItem('CorrActAppRoleID')?.toString()=="106")
    querystring=querystring+ '&agency_id=526'
 
  if(isActive!=-1)
     querystring=querystring+ '&is_active='+isActive
    if(managerId!=0)
  querystring=querystring+ '&manager_id='+ managerId
if(isEmpNotified!=-1)
  querystring=querystring+ '&is_emp_notified='+ isEmpNotified
if (isOpen!=-1)
 querystring=querystring+ '&is_open='+isOpen
if (violTypeId!=0)
  querystring=querystring+ '&violation_type_id='+violTypeId
if(isPending==1)
  querystring=querystring+ '&is_pending=1'


    return this.http
        .get<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&'+querystring.substring(1,querystring.length))
        .pipe(
          map((res: any) => res)
        );
  }

  getCorrectiveActionCreatedByMeforEmp = (badgeID: number,statusID: number,empID:number) : Observable<any> => {
    return this.http
        .get<any>(this.baseURL + 'corr_action?query_hint=get_list_of_corrective_action&assigned_to_id='+badgeID+"&corr_act_status_id="+statusID+"&emp_id="+empID)
        .pipe(
          map((res: any) => res)
        );
  }

  getAllCorrectiveAction = () : Observable<any> => {
    return this.http
        .get<any>(this.baseURL + 'corr_action')
        .pipe(
          map((res: any) => res)
        );
  }

  createCorrectiveAction = (reqBody:any) : Observable<any> => {
    return this.http
        .post<any>(this.baseURL + 'corr_action',reqBody)
        .pipe(
          map((res: any) => res)
        );
  }

  updateCorrectiveAction = (corr_act_id:string,reqBody:any) : Observable<any> => {
    return this.http
        .put<any>(this.baseURL + 'corr_action?corr_act_id='+corr_act_id,reqBody)
        .pipe(
          map((res: any) => res)
        );
      }

    setEMPResponseCorrAct = (corr_act_id:string,reqBody:any) : Observable<any> => {
          return this.http
              .post<any>(this.baseURL + 'corr_action/emp?corr_act_id='+corr_act_id,reqBody)
              .pipe(
                map((res: any) => res)
              );
  }

  getEmployeeCorrActAppRole(empID:number)
  {

    return this.http
    .get<any>(this.baseURL + 'app_role_id?emp_id='+empID)
    .pipe(
      map((res: any) => res)
    );

  }

  getEmployeeCorrActMgrHR(plant:string)
  {

    return this.http
    .get<any>(this.baseURL + 'managers?plant='+plant)
    .pipe(
      map((res: any) => res)
    );

  }


  getAllEmployees = () : Observable<EmployeeModel[]> => {
    let querystring:string=""
    if (sessionStorage.getItem('CorrActAppRoleID')?.toString()=="106")
    querystring=querystring+ '&agency_id=526'
    return this.http
        .get<EmployeeModel[]>(this.baseURL + 'emp?query_hint=get_emp_epicor_info'+querystring)
        .pipe(
          map((res: any) => res)
        );
  }




}
