<ion-content class="login-register">
  <div class="flex-center">
    <ion-card class="register-bx">
      <ion-card-header>
        <div class="flex-center"><img alt="homepage" src="assets/background/mim_log.png" width="55px">
          <ion-card-title>Register</ion-card-title>
        </div>
      </ion-card-header>

      <ion-card-content>
        <form [formGroup]="registerForm">
          <ion-item class="formInputs">
            <ion-input label="EmployeeID" labelPlacement="floating" type="text" maxlength="6" formControlName="empID"
              class="form-control" placeholder="EmployeeID"></ion-input>
          </ion-item>
          <ion-label color="danger"
            *ngIf="this.registerForm.controls['empID'].touched && this.registerForm.hasError('required','empID')">*
            EmployeeID is required</ion-label>
          <ion-label color="danger"
            *ngIf="this.registerForm.controls['empID'].touched && this.registerForm.hasError('pattern','empID')">*
            Please enter a valid EmployeeID</ion-label>

          <ion-item class="formInputs">
            <ion-input label="Email" labelPlacement="floating" type="text" formControlName="email" class="form-control"
              placeholder="Email"></ion-input>
          </ion-item>

          <ion-label color="danger"
            *ngIf="this.registerForm.controls['email'].touched && this.registerForm.hasError('required','email')">*
            Email is required</ion-label>

          <ion-label color="danger"
            *ngIf="this.registerForm.controls['email'].touched && this.registerForm.hasError('pattern','email')">*
            Please enter a valid email address</ion-label>

          <ion-item>
            <ion-input label="Hire Year" labelPlacement="floating" type="text" maxlength="4" formControlName="hireYear"
              (keypress)="eventHandler($event)" class="form-control" placeholder=" "></ion-input>
          </ion-item>
          <ion-label color="danger"
            *ngIf="this.registerForm.controls['hireYear'].touched && this.registerForm.controls['hireYear'].invalid">*
            Hire Year is required</ion-label>

          <ion-button class="registerBtn" size="full" [disabled]="loading"
            (click)="registerUser()">Register</ion-button>
          <ion-button class="helpBtn" size="full" [disabled]="loading" (click)="SendEmail()">Help</ion-button>
          <div class="flex-row flex-space-between">
            <div class="forgot" style="margin-bottom: 10px;">
              <a class="link" routerLink="/login"><ion-icon name="arrow-undo-outline"></ion-icon>Return To Login</a>
            </div>
          </div>
        </form>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>