import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { MenuItems } from './menu-items/menu-items';




@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: []
})

export class SharedModule { }
