import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from '../../services/authorization.service';

import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  resetForm!: FormGroup
  hide = true;
  showPassword: boolean = false;
  empID: any = this.getEmpId()
  hiredYear: any = this.getHiredYear()


  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthorizationService,
    private alertCtrl: AlertController,
    private alertController: AlertController
  ) {
    this.resetForm = this.formBuilder.group({
      employeeId: new FormControl(''),
      newPassword: new FormControl('', [Validators.required]),
      resetNewPassword: new FormControl('', [Validators.required]),
      passwordHint: new FormControl(''),

    })

    this.resetForm.controls['employeeId'].setValue(this.empID)
    this.resetForm.controls['employeeId'].disable()
  }

  ngOnInit() {
    this.getEmpId()
    this.getHiredYear()
  }

  getEmpId() {
    let empID: any
    if (this.authService.empID == undefined) {
      empID = sessionStorage.getItem('EMP_ID')
    } else {
      empID = this.authService.empID
    }
    return empID
  }

  getHiredYear() {
    let hiredYear: any
    if (this.authService.yearHired == undefined) {
      hiredYear = sessionStorage.getItem('EMP_HireYear')
    } else {
      hiredYear = this.authService.yearHired.split("-")[0]
    }
    return hiredYear

  }

  resetPassword() {
    this.resetForm.markAllAsTouched();
    const { newPassword, resetNewPassword, passwordHint } = this.resetForm.value;

    if (newPassword == resetNewPassword) {
      let newPassWordBody: any = {
        'EmpID': this.authService.empID,
        'UserPassword': newPassword,
        'PasswordHint': passwordHint
      }

      this.authService.changePassWord(newPassWordBody).subscribe(
        async (res: any) => {
          console.log(res)
          const alert = await this.alertController.create({
            header: 'Success',
            subHeader: 'Password has been changed Successfully!',
            buttons: ['Okay']
          })
          await alert.present()
          this.router.navigateByUrl('login')
        }
      )
    }
    else {
      alert('Passwords are not matching.')
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  eventHandler(event: any) {
    if (event.key == 'Enter') {
      this.resetPassword();
    }
  }
}