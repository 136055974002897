import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
}
  from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AlertController, ModalController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { AlertModelComponent } from 'src/app/features/components/corrective-action/alert-model/alert-model.component';

@Injectable({
  providedIn: 'root'
})
export class InterceptorErrorService implements HttpInterceptor {
  popUp:boolean = false;

  constructor(
    private alertCtrl: AlertController,
    private router: Router,
    private auth: AuthorizationService,
    private loadingCtrl: LoadingController,

    private modelCtrl: ModalController
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError(error => {
          if (error.status == 401) {
            this.dismissLoading();
            this.msgDialog("Token is expired. Please log back in!");
          }
          return throwError(error);
        }
        ));
  }

  async dismissLoading() {
    const loading = await this.loadingCtrl.getTop();
    if (loading) {
      await loading.dismiss();
    }
  }

  async msgDialog(msg: string) {
    if(!this.popUp) {
      this.popUp = true;
      const modal = await this.modelCtrl.create({
        component: AlertModelComponent,
        cssClass: 'confirmation',
        componentProps: {
          note: '',
          DialogBoxFor: 'Message',
          lbl: msg,
          title: 'Login expired. Please relogin!'
        }
      });
      await modal.present();
      modal.onDidDismiss().then((m) => {
        this.popUp = false;
        this.auth.logOut();
      })
    }
  }
}