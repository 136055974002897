// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: true,
  baseUrl:'https://portal.materialinmotion.com/empApi/',
  wfmAPIBaseURL:'https://portal.materialinmotion.com/wfmApi/',
  tmoAPIBaseURL:'https://portal.materialinmotion.com/tmoApi/',
  baseCorrActUrl:'https://portal.materialinmotion.com/corractApi/',
 // userProfileImageURL: 'https://portal.materialinmotion.com/wfmApi/employee/image?EmpID=',
  appVersion: '(' + require('../../package.json').version+ ')',
  //wfmAppUrl:'https://portal.materialinmotion.com/wfmApp/',
  //tmoAppUrl:'https://portal.materialinmotion.com/tmoApp/',
  firebase: {
    apiKey: "AIzaSyAPMB2yngIbf9p1JiUzZJM3Pwjx4xBHnYM",
    authDomain: "material-in-moti-1562619632159.firebaseapp.com",
    databaseURL: "https://material-in-moti-1562619632159.firebaseio.com",
    projectId: "material-in-moti-1562619632159",
    storageBucket: "material-in-moti-1562619632159.appspot.com",
    messagingSenderId: "715730761198",
    appId: "1:715730761198:web:736ea5586b84f2460ccaff",
    measurementId: "G-Y4RGVQJW97"
  }
};
