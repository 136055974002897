import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CertificationModel, EmpHrsWorkedModel, EmpOccurancesModel, EmpSupervisorModel, EmpWorkShoesModel, EmployeeInfoModel, EmployeeModel, TotalHrsWorkedToGradModel } from '../models/employee';
import { EmpNotificationModel } from '../models/mimJobsModel';
//import { AngularFirestore } from 'angularfire2/firestore';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  baseURL = environment.baseUrl;
  wfmAPIURL = environment.wfmAPIBaseURL;
  tmoAPIURL= environment.tmoAPIBaseURL;

  constructor(private http: HttpClient) { }

  getAllEmployees = () : Observable<EmployeeModel[]> => {
    return this.http
        .get<EmployeeModel[]>(this.baseURL + 'employee')
        .pipe(
          map((res: any) => res)
        );
  }

  getEmpInfo = (empID:number) : Observable<EmployeeModel[]> => {
    return this.http
        .get<EmployeeModel[]>(this.baseURL + 'employee?emp_id='+empID)
        .pipe(
          map((res: any) => res)
        );
  }


  getEmpInfoImage = (empID:number) : Observable<any> => {

    return this.http
         .get<any>(this.wfmAPIURL + 'employee/image?EmpID='+empID, {responseType: 'blob' as 'json'});

  }

  getEmployees = (mgrID: number) : Observable<EmployeeModel[]> => {
    return this.http
        .get<EmployeeModel[]>(this.baseURL + 'employee?manager_id=' + mgrID)
        .pipe(
          map((res: any) => res)
        );
  }



  getEmpinfoBystr = (namestr: string) : Observable<EmployeeModel[]> => {
    return this.http
        .get<EmployeeModel[]>(this.baseURL + '/employee/name?emp_name=' + namestr)
        .pipe(
          map((res: any) => res)
        );
  }

  getEmployee=():Observable<EmployeeInfoModel[]>=>{
    return this.http
    .get<EmployeeInfoModel[]>(this.baseURL + 'employee')
    .pipe(
      map((res:any)=>res)
    )
  }

  getEmployeeDetails = (badgeID:number, appRoleID:number) : Observable<EmployeeInfoModel[]> =>{
    return this.http
      .get<EmployeeInfoModel[]>(this.baseURL + 'employee?emp_id=' + badgeID + '&app_role_id=' + appRoleID )
      .pipe(
        map((res:any)=>res)
      )
    }

  getEmpSupervisorInfo = (empID:number): Observable<EmpSupervisorModel[]>=>{
    return this.http
    .get<EmpSupervisorModel[]>(this.baseURL + 'employee/supervisor?emp_id=' + empID)
    .pipe(
      map((res:any)=>res)
    )
  }

  getEmpOccurrencePoints = (EmpID:number):Observable<any> => {
    return this.http
    .get<any>(this.baseURL + 'occurrence/points?emp_id=' + EmpID)
    .pipe(
      map((res:any)=>res)
    )
  }

  getAllEmpOccurrencePoints = (expType:string,agencyID:number,shift:string,pointRange:string):Observable<any> => {
    let qryString:string=""
    if(expType!="ALL")
      qryString=  qryString+'&expense_type='+expType
    if(agencyID!=0)
     qryString=  qryString+'&agency_id='+agencyID
    if(shift!="ALL")
      qryString=  qryString+'&shift='+shift
    if(pointRange!="ALL")
      qryString=  qryString+'&point_range='+pointRange
console.log(qryString)
    return this.http
    .get<any>(this.baseURL + 'occurrence/points?total_occurrences_point=6' + qryString)
    .pipe(
      map((res:any)=>res)
    )

  }

  getEmpOccurrencesDetails = (EmpID:number): Observable<EmpOccurancesModel[]> => {
    return this.http
    .get<EmpOccurancesModel[]>(this.baseURL + 'occurrence/details?emp_id=' + EmpID)
    .pipe(
      map((res:EmpOccurancesModel[])=> res)
    )
  }

 getHourlyAccrualRate = (EmpID:number):Observable<any>=>{
  return this.http
  .get<any>(this.tmoAPIURL+'occurrence/' + EmpID)
  .pipe(
    map((res:any)=>{
      return res['Occurrence']
    })
  )
 }

getHrsWorkedForEmp = (EmpID:number): Observable<EmpHrsWorkedModel[]> =>{
    return this.http
    .get<EmpHrsWorkedModel[]>(this.baseURL + 'hrs-worked?emp_id=' + EmpID)
    .pipe(
      map((res:EmpHrsWorkedModel[])=> res)
    )
  }

  getTotalHrsWorkedToGraduate = (EmpID:number):Observable<TotalHrsWorkedToGradModel[]> => {
    return this.http
    .get<TotalHrsWorkedToGradModel[]>(this.baseURL + 'hrs-worked/total?emp_id=' + EmpID)
    .pipe(
      map((res:any)=> res)
    )
  }

  getEmpCertificationDetails(EmpID: number){
    return this.http
    .get<CertificationModel[]>(this.baseURL + 'certification?emp_id=' + EmpID)
    .pipe(
      map((res:any)=>res)
    )
  }

  getEmpsSoonToExpireCerts(manager_id:number){
    return this.http
    .get<any>(this.baseURL +
    'certification?manager_id='+ manager_id)
    .pipe(
      map((res:any)=>res)
    )
  }



  // getNotifications = ():Observable <EmpNotificationModel> =>{
  //   return this.http.get<EmpNotificationModel[]>(this.cloudAPIBaseURL + 'notification')
  //   .pipe(
  //     map((res:any)=>{
  //       return res["Notification"];
  //     })
  //   )
  // }
  getServerDatetime(plant: any): Observable<any>{
    
    return this.http
    .get<any>(this.tmoAPIURL + 'server-time/' + plant)
    .pipe(map((res: Date) => res));
  }

   getTimeSheetInfo(EmpID:number){
    return this.http
    .get<any>(this.tmoAPIURL + 'timesheets/'+ EmpID )
    .pipe(
      map((res:any)=>res)
    )
   }

   postCallOut(reqBody:any){
    return this.http
    .post(this.tmoAPIURL + 'callout' , reqBody)
  }

  getMimAppLinksList(hasMimEmail:boolean, isItGroupMemb:boolean, plant:string){
    return this.http
    .get(this.baseURL + 'mim-app-links?has_mim_email='+ hasMimEmail + '&is_it_group_memb=' + isItGroupMemb + '&plant=' + plant)
    .pipe(
      map((res:any)=>res)
    )
  }

    getAnnouncementImageList(){
      return this.http
      .get(this.baseURL + 'announcements')
      .pipe(
        map((res:any)=>res)
      )
    }

    getEmpWorkShoesDetails(EmpID: number){
      return this.http
      .get<EmpWorkShoesModel[]>(this.baseURL + 'work-shoes?emp_id=' + EmpID)
      .pipe(
        map((res:any)=>res)
      )
    }

    sendEmail = (reqBody:any) : Observable<any> => {
      return this.http
          .post<any>(this.baseURL + 'notification/email-hr',reqBody)
          .pipe(
            map((res: any) => res)
          );
    }


  }
