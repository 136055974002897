export enum Role 
{
    MIM_Salaried='MIMFB-SAL',
    MIM_Hourly='MIMFB-HRL',
    IOS_EXMPT='IOS-EXMP',
    IOS_NONEXMPT='IOS-NON-EXMP',
    IOS_NONEXMPT_AGENCY='NON-MIMFB/IOS-NON-EXMP',
    MIM_NF='MIMNF',
    NON_MIMNF='NON-MIMNF'

  }