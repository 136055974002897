<ion-toolbar>
  <ion-title class="toolbar-title-wb">  {{title}}
  </ion-title>
</ion-toolbar>

<ion-content>
  <div *ngIf="DialogBoxFor=='Confirmation'">
    <ion-item >
      {{lbl}}
    </ion-item>
  </div>

    <ion-item lines="none" *ngIf="DialogBoxFor=='Inactivity'">
      <h1>
      {{lbl}}
    </h1>
    </ion-item>

  <div *ngIf="DialogBoxFor=='callOut'">
    <ion-item >
      {{lbl}}
    </ion-item>
    <ion-item>
      <h2>
        {{note}}
      </h2>
    </ion-item>
  </div>

  <div *ngIf="DialogBoxFor=='Message'">
    <ion-item lines='none'>
      {{lbl}}
    </ion-item>
  </div>


  <div *ngIf="DialogBoxFor=='Notes' ||  DialogBoxFor=='Send Email'">
    <ion-item lines='none'>
      {{lbl}}
    </ion-item>

    <ion-item lines='none'>
      <ion-textarea  [autoGrow]="false"  fill="solid" style="height: 120px;"  [counter]="true" maxlength="1200"
        [(ngModel)]="note"></ion-textarea>



    </ion-item>
  </div>

  <div *ngIf="DialogBoxFor=='occurrenceDispute'" >
    <ion-item lines='none'>
      {{lbl}}
    </ion-item>

    <ion-item lines='none'>
       <ion-textarea  [autoGrow]="false"  style="height: auto;" fill="solid" [counter]="true" maxlength="1200"
        [(ngModel)]="note">

      </ion-textarea>

    </ion-item>
  </div>


  <div *ngIf="DialogBoxFor=='Assignee'">
    <ion-item lines='none'>
      {{lbl}}
    </ion-item>

   <ion-item lines='none'>
      <input class=" inputText inputInDarkMode" list="myList" placeholder="Enter Badge ID" [(ngModel)]="note">

      <datalist id="myList" interface="popover" class="scrollable datalistInDark">
        <option class="inputText inputInDarkMode"  *ngFor="let emp of lstEmployee;" [value]="emp.EmpID">{{emp.EmpName}}</option>
      </datalist>

    </ion-item>
  </div>

</ion-content>

<ion-footer>
  <ion-toolbar class="footer">

     <ion-buttons slot="start">
      <ion-button color="dark" (click)="cancel()">{{leftBtn}}</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="confirm()">{{rightBtn}}</ion-button></ion-buttons>
  </ion-toolbar>
</ion-footer>