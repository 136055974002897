import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-release-details',
  templateUrl: './new-release-details.component.html',
  styleUrls: ['./new-release-details.component.scss']
})
export class NewReleaseDetailsComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<NewReleaseDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
  }

  ngOnInit(): void {
    this.showNewReleaseDetails()
  }

  showNewReleaseDetails() {
    this.dialogRef
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
