import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PreloadAllModules, RouteReuseStrategy, Router } from '@angular/router';
import { RouterModule } from '@angular/router';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule, routes } from './app-routing.module';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {MatFormFieldModule} from '@angular/material/form-field';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RegistrationComponent } from './core/components/registration/registration.component';
import { SharedModule } from './shared/shared.module';
import { MainComponent } from './core/components/main/main.component';
import { ResetPasswordComponent } from './core/components/reset-password/reset-password.component';
import { LoginComponent } from './core/components/login/login.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { HomeTabsComponent } from './core/components/home-tabs/home-tabs.component';
//import { FeaturesModule } from './features/features.module';
import {CookieService} from 'ngx-cookie-service';
import { ChangePasswordComponent } from './core/components/change-password/change-password.component';
//import { InputMaskModule } from 'ionic-input-mask';
import { InterceptorService } from './core/services/interceptor.service';
import { InterceptorErrorService } from './core/services/interceptor-error.service';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@NgModule({
  declarations: [AppComponent, RegistrationComponent,ResetPasswordComponent,
    LoginComponent,
    MainComponent,
    ChangePasswordComponent

    ],
  imports: [BrowserModule, 
   // InputMaskModule,
    IonicModule.forRoot(), 
    
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    }),
    
    ReactiveFormsModule,
    AppRoutingModule,
    MatFormFieldModule,
    //MenuPageModule,
    SharedModule,
    NgxDatatableModule,
   // FeaturesModule,
   
  
    HttpClientModule, FormsModule, ReactiveFormsModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },   
    {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true,
    }, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorErrorService,
      multi: true,
      }
      , provideAnimationsAsync(),[CookieService]]
  ,
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [RouterModule]
 
})
export class AppModule {}
