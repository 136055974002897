import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from '../../services/authorization.service';

import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})

export class ResetPasswordComponent implements OnInit {

  resetForm!: FormGroup
  hide = true;
  showPassword: boolean = false;

  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthorizationService,
    private alertCtrl: AlertController,
    private alertController: AlertController
  ) {
    this.resetForm = this.formBuilder.group({
      employeeId: new FormControl(''),
    })
  }

  ngOnInit() { }

  resetPassword() {
    this.resetForm.markAllAsTouched();
    const { employeeId } = this.resetForm.value;

    let resetPassWordBody: any = {
      'EmpID': employeeId,
    }

    this.authService.resetPassWord(resetPassWordBody).subscribe(
      async (res: any) => {
        console.log(res[0].api_message)
        if (res[0].api_message.toString().includes('Invalid password | Or | Emp is not register!')) {
          const alert = await this.alertController.create({
            header: 'Success',
            subHeader: 'You are not registered. Please register!',
            buttons: ['Okay']
          })
          await alert.present()
          this.router.navigateByUrl('registration')
        } else {
          const alert = await this.alertController.create({
            header: 'Success',
            subHeader: 'Temporary password has been sent to your email! Please be aware it may take up to 10-15 minutes to come through.',
            buttons: ['Okay']
          })
          await alert.present()
          this.router.navigateByUrl('login')
        }
      })
  }

  eventHandler(event: any) {
    if (event.key == 'Enter') {
      this.resetPassword();
    }
  }
}
