import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, MenuController } from '@ionic/angular';
import { AuthorizationService } from '../../services/authorization.service';
import { Router } from '@angular/router';
import { User } from 'src/app/features/models/User';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { __await } from 'tslib';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;
  hide = true;
  passWord: string = '';
  showPassword: boolean = false;
  showLoginError: boolean = false;
  showPasswordHint: boolean = false;
  loginErrorMessage: string = "";
  passwordHint: string = "";
  hintText: string = "Show Hint";

  username!: string;
  password!: string
  version!: string

  constructor(public menuCtrl: MenuController,
    private authService: AuthorizationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private alertCtrl: AlertController,
    private cookieService: CookieService,
  ) {
    this.initializeForm();
    this.version = environment.appVersion;

  }

  ionViewWillEnter() {
    this.menuCtrl.enable(false);
    this.initializeForm();
  }

  ngOnInit() {
    this.updateTheme();

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', this.updateTheme);
  }

  updateTheme() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    document.body.setAttribute('color-theme', prefersDark ? 'dark' : 'light');
    document.documentElement.classList.toggle('ion-palette-dark', prefersDark);
  }

  initializeForm() {
    this.loginForm = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    })
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  async login(): Promise<void> {
    this.loginForm.markAllAsTouched();

    const formData: any = new FormData()
    formData.append('username', this.loginForm.get('username')?.value.trim());
    formData.append('password', this.loginForm.get('password')?.value.trim());

    if (this.loginForm.status === 'INVALID') {
      return;
    }

    this.authService.getauthToken(formData).subscribe(async resToken => {

      await sessionStorage.setItem('access_token', resToken.access_token);
      await this.cookieService.deleteAll('/');
      await this.cookieService.deleteAll('/empApp');
      await this.cookieService.set('access_token', resToken.access_token, undefined, '/');

      await this.authService.getUser(resToken.access_token).subscribe(async resUser => {

        let user: any
        user = resUser[0]

        this.authService.empName = resUser[0].EmpName;
        this.authService.empID = resUser[0].EmpID;
        this.authService.empEmail = resUser[0].EmpEmail;
        this.authService.mimEmail = resUser[0].mimEmail;
        this.authService.appRoleID = resUser[0].AppRoleID;
        this.authService.jobRole = resUser[0].JobRole;
        this.authService.jobTitle = resUser[0].JobTitle;
        this.authService.hiredOn = resUser[0].HiredOn;
        this.authService.agency = resUser[0].Agency;
        this.authService.shift = resUser[0].Shift;
        this.authService.empAddress = resUser[0].EmpAddress;
        this.authService.EmpSubGroupID = resUser[0].EmpSubGroupID;
        this.authService.EmpSubGroup = resUser[0].EmpSubGroup;
        this.authService.dept = resUser[0].Dept;

        await this.authService.setUserAfterLogin(user);

        if (this.isAuthorized(user)) {
          this.showLoginError = false

          this.router.navigate(['employee-portal/home']);
        }
        else {
          this.handleUnauthenticatedUser();
        }
      }
      );
    }, async err => {
      if (err.status == 500) {
        const alert = this.alertCtrl.create({
          header: 'Login Failed',
          subHeader: err.error.detail,
          buttons: ['Okay'],
        });
        (await alert).present();
      }
      this.handleLoginError();
    })
  }

  resetPassword() {
    this.router.navigateByUrl('reset-password');
  }

  navigateToRegistration() {
    this.router.navigate(['/registration']);
    this.loginErrorMessage = "";
  }

  private isAuthorized(user: User): boolean {
    return user.EmpID !== null
  }

  togglePasswordHint() {
    var empId = parseInt(this.loginForm.get('username')?.value.trim());
    if (!isNaN(empId) && empId.toString().length === 6) {
      this.showPasswordHint = !this.showPasswordHint;
      if (this.showPasswordHint) {
        this.authService.userHint(empId).subscribe((response: any) => {
          if (response[0].PasswordHint.length > 0) {
            var hint = response[0].PasswordHint;
            this.passwordHint = "Password Hint: " + hint;
          } else {
            this.passwordHint = "No hint found."
          }
          if (this.loginErrorMessage == "Please enter a valid Employee ID.")
            this.showLoginError = false;
        }, (error: any) => {
          console.error('Error fetching password hint:', error);
        }
        );
        this.hintText = "Hide Hint";
      } else {
        this.hintText = "Show Hint";
      }
    } else {
      this.showLoginError = true;
      this.loginErrorMessage = "Please enter a valid Employee ID.";
    }
  }

  handleUnauthenticatedUser() {
    this.showLoginError = true;
    this.loginErrorMessage = "Incorrect username or password. Please try again.";
    this.authService.invalidateUser();
  }

  handleLoginError() {
    this.showLoginError = true;
    this.loginErrorMessage = "Error Occured. Please try again.";
    this.authService.invalidateUser()
  }

  eventHandler(event: any) {
    if (event.key == 'Enter') {
      this.login();
    }
  }
}