import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { CorrActEmployeeModel } from 'src/app/features/models/recomCorrAction';
import { CorrectiveActionService } from 'src/app/features/services/corrective-action.service';
import { EmployeeService } from 'src/app/features/services/employee.service';

@Component({
  selector: 'app-alert-model',
  templateUrl: './alert-model.component.html',
  styleUrls: ['./alert-model.component.scss'],
})
export class AlertModelComponent implements OnInit {
  note: string = "";
  leftBtn: string = "Cancel"
  rightBtn: string = "Update";
  lbl: string = ""
  empName: string = "";
  lstEmployee: CorrActEmployeeModel[] = []
  title: string = ""

  public DialogBoxFor: string = ""
  constructor(
    private modalController: ModalController,
    private corrSvc: CorrectiveActionService,
    private employeeServ: EmployeeService,
    public authServ: AuthorizationService
  ) {
  }

  ngOnInit() {
    if (this.DialogBoxFor == 'Confirmation') {
      this.leftBtn = "No"
      this.rightBtn = "Yes";
    }
    if (this.DialogBoxFor == 'callOut') {
      this.leftBtn = "Cancel"
      this.rightBtn = "OK";
    }
    else if (this.DialogBoxFor == 'Message') {
      this.leftBtn = ""
      this.rightBtn = "OK";
    }
    else if (this.DialogBoxFor == 'Send Email') {
      this.leftBtn = "Cancel"
      this.rightBtn = "Send";
    }
    else if (this.DialogBoxFor == 'occurrenceDispute') {
      this.leftBtn = "Cancel"
      this.rightBtn = "Send";
    }
    else if (this.DialogBoxFor == 'Inactivity') {
      this.leftBtn = "No";
      this.rightBtn = "Yes";
    }

    if (this.DialogBoxFor == 'Assignee') {
      this.getEmployeeName();
    }
  }
  customCounterFormatter(inputLength: number, maxLength: number) {
    return `${maxLength - inputLength} characters remaining`;
  }

  cancel() {
    return this.modalController.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalController.dismiss(this.note, 'confirm');
  }

  getEmployeeName() {
    this.corrSvc.getEmployeeCorrActMgrHR(this.authServ.plant).subscribe(res => {
      this.lstEmployee = res;
      this.lstEmployee = this.lstEmployee.filter(x => x.AppRoleID != 104 && !x.EmpName.includes('POSITION'))
    })
  }
}
