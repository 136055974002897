import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthorizationService } from '../../services/authorization.service';
import { EmployeeService } from 'src/app/features/services/employee.service';
import { AlertModelComponent } from 'src/app/features/components/corrective-action/alert-model/alert-model.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  registerForm!: FormGroup
  hide = true;
  password: string = '';
  showPassword: boolean = false;
  loading: boolean = false;
  helpEmail: string = "fbonboarders@materialinmotion.com";

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthorizationService,
    private employeeService: EmployeeService,
    private modelCtrl: ModalController,
  ) {
    this.registerForm = this.formBuilder.group({
      empID: new FormControl("", [Validators.required, Validators.pattern("^[0-9]*$")]),
      email: new FormControl("", Validators.compose([Validators.required, Validators.pattern("[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}")])),
      hireYear: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
  }

  registerUser(): void {
    this.registerForm.markAllAsTouched();

    const empID = this.registerForm.value.empID.trim()
    const email = this.registerForm.value.email.trim()
    const hireYear = this.registerForm.value.hireYear.trim()

    if (this.registerForm.status == 'INVALID') {
      this.loading = false;
      return;
    } else {
      this.loading = true;

      this.authService.registerUser(empID, email, hireYear).subscribe({
        next: async (res: any) => {
          //console.log("Registration successful", res);
        },
        complete: () => {
          this.loading = false;
        }
      });
    }
  }


  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  eventHandler(event: any) {
    if (event.key == 'Enter' && !this.loading) {
      this.registerUser();
    }
  }

  async SendEmail() {
    const empID = this.registerForm.value.empID.trim();
    const email = this.registerForm.value.email.trim();
    const hireYear = this.registerForm.value.hireYear.trim();

    const valid = this.validateRegistrationForm(empID, email, hireYear);
    if (valid == "ok") {
      const modal = await this.modelCtrl.create({
        component: AlertModelComponent,
        cssClass: 'dialog',

        componentProps: {
          note: '',
          DialogBoxFor: 'Send Email',
          lbl: 'Message',
          title: 'Registration Help'
        }
      });
      await modal.present();
      modal.onDidDismiss().then((m) => {

        if (m.role == 'confirm')
          this.callEmailService(m.data)
      });
    } else {
      this.msgDialog(valid);
    }
  }

  callEmailService(message: string) {
    const empID = this.registerForm.value.empID.trim();
    const email = this.registerForm.value.email.trim();
    const hireYear = this.registerForm.value.hireYear.trim();

    let reqBody = {
      "EmpID": empID,
      "Body": 'Registration Issue:<br><br> Employee ID: ' + empID + '<br>Email: ' + email + '<br>Hire Year: ' + hireYear + '<br><br>Hi Team, <br> ' + message,
      "ToEmailAddress": this.helpEmail,
      "Issue": 'Registration'
    }

    this.employeeService.sendEmail(reqBody).subscribe((res: any) => {
      this.msgDialog("Email sent to the Support Team. You will be assisted with your registration soon.");
      this.registerForm.reset();
    })
  }

  async msgDialog(msg: string) {
    const modal = await this.modelCtrl.create({
      component: AlertModelComponent,
      cssClass: 'confirmation',
      componentProps: {
        note: '',
        DialogBoxFor: 'Message',
        lbl: msg,
        title: 'Message'
      }
    });
    await modal.present();
    modal.onDidDismiss().then((m) => {
    }
    )
  }

  validateRegistrationForm(empID: number, email: string, hireYear: number) {
    const isEmpIDValid = empID.toString().length === 6;
    if (!isEmpIDValid) return "Please input a valid Employee ID.";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email);
    if (!isEmailValid) return "Please input your personal email.";
    const isHireYearValid = hireYear > 2000 && hireYear <= new Date().getFullYear();
    if (!isHireYearValid) return "Please input your hire year.";
    return "ok";
  }
}