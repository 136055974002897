import { Component } from '@angular/core';
import { register } from 'swiper/element/bundle'
register()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  
   public appPages = [
    { title: 'Inbox', url: 'registration', icon: 'mail' },
  ];

  public MenuItems = [
    {
      link: "correctiveaction",
      name: "Corrective Action",
      icon: "av_timer",
    
    },
    {
      link: "regisration",
      name: "Registration",
      icon: "av_timer",
    
    },
    {
      link: "notification",
      name: "Notification",
      icon: "av_timer",
    
    },
   
  ];
  public labels = ['Occurances', 'Corrective Action','Certification','Change of Status'];
  constructor() {}
}
